export const CREDIT_BASE_URL = "https://connect.creditsafe.com/v1/"
// export const PERSONA_BASE_URL = "https://services.paymix.eu/softpos"
// export const PERSONA_BASE_URL = "https://softposstaging.paymix.eu/"
export const PERSONA_BASE_URL = "https://softposapi.paymix.eu"
export const GETID_BASE_URL = "https://financeincorp.getid.ee/api/v1/application/"

export const URL_BUSINESS_INFO = "/BusinessInformation"
export const URL_UBO = "/UBO"
export const URL_ID_VERIFY = "/IDVerify"
export const URL_UPLOAD = "/SendDocument"
export const URL_SEND_EMAIL = (EMAIL, CODE) => `https://www.paymix.eu/email/email.php?email=${EMAIL}&code=${CODE}`